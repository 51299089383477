<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <b-form @submit.prevent="filterByDate" class="mt-4">
            <b-row>
              <b-col md="4">
                <b-form-group label="From this date">
                  <b-form-datepicker
                    v-model="form.start_date"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="To this date">
                  <b-form-datepicker
                    v-model="form.end_date"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col md="3" style="padding-top: 2em;">
                <b-button type="submit" variant="danger" class="mr-1" style="width:38%;">Filter</b-button>
                <b-button type="submit" variant="outline-danger" @click="reset" style="width:38%;">Reset</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <div>
        <h3>Applications</h3>
        <b-row>
          <!-- timeline chart -->
          <b-col md="8">
            <trend-chart
              label="ISBNs issued"
              :months="isbn.trend.months"
              :values="isbn.trend.values"
              :key="isbn.trend.loaded"
            ></trend-chart>
            <b-alert show variant="info">
              <a :href="dlink" download>Download publications as csv</a>
            </b-alert>
            <div class="d-flex mt-2">
              <b-card style="width:22.5em;" class="mr-1 isbn-extra-card">
              <b-card-body class="py-1">
                <div class="text-center" style="font-size:2em; font-weight: 600;">{{ isbn.totals[3].value }}</div>
                <div class="text-center slabel">{{isbn.totals[3].label }}</div>
              </b-card-body>
            </b-card>
            <b-card style="width:22em;" class="isbn-extra-card">
              <b-card-body class="py-1">
                <div class="text-center" style="font-size:2em; font-weight: 600;">{{ isbn.totals[4].value }}</div>
                <div class="text-center slabel">{{isbn.totals[4].label }}</div>
              </b-card-body>
            </b-card>
            </div>
          </b-col>
          <b-col md="4">
            <b-card v-for="total in isbn.totals.slice(0,3)" :key="total.id" class="my-1 isbn-stat-card">
              <b-card-body class="py-1">
                <div class="text-center svalue" style="font-size:2em; font-weight: 600;">{{ total.value }}</div>
                <div class="text-center slabel">{{ total.label }}</div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<style scoped lang="scss">
.isbn-stat-card:nth-child(1){
  color:#5cb85c ;
  .slabel{
    color:#097969;
  }
}
.isbn-stat-card:nth-child(2){
  color:#0D98BA;
  .slabel{
    color:#097969;
  }
}
.isbn-stat-card:nth-child(3){
  color:#0275d8;
  .slabel{
    color:#097969;
  }
}
.isbn-extra-card{
  color:#D22B2B;
  
  .slabel{
    color: #4A0404;
  }
}
</style>
<script>
import TrendChart from "../../components/TrendChart.vue";
import moment from "moment";
// const _ = require("lodash");
import { axiosApiInst } from "../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND + "/reports";
const totals = [{label:"Applications Approved",value:0,id:"approved"},
          {label:"Applications At Approval",value:0,id:"verified"},
          {label:"Applications At Verification",value:0,id:"unverified"},
          {label:"Applications Rejected By Verifier",value:0,id:"rejected/verifier"},
          {label:"Applications Rejected By Approver",value:0,id:"rejected/approver"}
        ];
export default {
  components: { TrendChart },
  computed:{
    dlink(){

      var url = new URL(BASE_URL + "/isbn.monthly.download");
      if(this.form.start_date && this.form.end_date){
        var params = new URLSearchParams();

        params.append("start_date", this.form.start_date);
        params.append("end_date", this.form.end_date);

        url.search = params.toString();
      }
      return url.toString();
    }
  },
  data() {
    return {
      form: {
        start_date: null,
        end_date: null,
      },
      isbn: {
        trend: { months: [], values: [] ,loaded:0},
        totals:Object.assign([],totals),
      },
      deposits:{
        trend: { months: [], values: [] ,loaded:0},
        totals:[
          {label:"Deposits Approved",value:0,id:"approved"},
          {label:"Deposits At Approval",value:0,id:"verified"},
          {label:"Applications At Verification",value:0,id:"unverified"},
        ]
      }
    };
  },
  mounted(){
    this.monthlyISBN();
    this.isbnTotals();
  },
  methods: {
    filterByDate() {
      this.monthlyISBN();
      this.isbnTotals();
    },
    reset(){
      this.form = {
        start_date: null,
        end_date: null,
      };
      this.filterByDate();
    },
    monthlyISBN() {
      axiosApiInst
        .get(BASE_URL + "/isbn.monthly", {
          params: {
            start_date: this.form.start_date,
            end_date: this.form.end_date,
          },
        })
        .then((res) => {
          const sortedData = res.data.sort((a, b) => new Date(a.month) - new Date(b.month));

          let months = [];
          let values = [];
          sortedData.forEach((stat) => {
            months = [
              ...months,
              moment(stat.month).format("MMM"),
            ];
            values = [
              ...values,
              stat.count,
            ];
          });

          this.isbn.trend["months"] = months;
          this.isbn.trend["values"] = values;
          this.isbn.trend.loaded+=1;
        });
    },

    isbnTotals(){
      this.isbn.totals = [{label:"Applications Approved",value:0,id:"approved"},
          {label:"Applications At Approval",value:0,id:"verified"},
          {label:"Applications At Verification",value:0,id:"unverified"},
          {label:"Applications Rejected By Verifier",value:0,id:"rejected/verifier"},
          {label:"Applications Rejected By Approver",value:0,id:"rejected/approver"}
        ];
        axiosApiInst.get(BASE_URL+"/isbn.totals",{
            params:{
                start_date:this.form.start_date,
                end_date: this.form.end_date,
            }
        }).then((res)=>{
          this.isbn.totals.forEach(stat=>{
            const tmp = res.data.find(el=>el.status==stat.id);
            if(tmp){
              stat["value"] = tmp["total"];
            }
          })
        })
    }
  },
};
</script>